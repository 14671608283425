import Order from "../models/Order";
import { BlockedDate } from "./types";

export const getNextOrderWed = (blockedDates?: BlockedDate[] | undefined) => {
  const now = new Date();
  let addDays = 0;
  // Next week if it's Sunday after 10pm, but going with midnight for now
  // if (now.getDay() === 0 && now.getHours() >= 22) {
  //   nextWeek = true;
  // }
  if (now.getDay() > 0 && now.getDay() < 4) {
    addDays += 7;
  }
  let newDate = now.getDate() + ((3 + 7 - now.getDay()) % 7) + (addDays || 0);
  if (blockedDates) {
    while (
      blockedDates.find(
        (date) =>
          new Date(date.date).toLocaleString().slice(0, 10) ===
          new Date(now.getFullYear(), now.getMonth(), newDate).toLocaleString().slice(0, 10)
      )
    ) {
      newDate += 7;
    }
  }
  return new Date(now.getFullYear(), now.getMonth(), newDate, 9, 0, 0);
};
export const getNextWed = (blockedDates?: BlockedDate[] | undefined) => {
  let now = new Date();
  let nowDate = now.getDate();
  // if (now.getDay() === 0 && now.getHours() >= 22) {
  //   nextWeek = true;
  // }
  if (blockedDates) {
    while (
      blockedDates.find(
        (date) =>
          new Date(date.date).toLocaleString().slice(0, 10) ===
          new Date(now.getFullYear(), now.getMonth(), nowDate).toLocaleString().slice(0, 10)
      )
    ) {
      nowDate += 7;
    }
  }

  return new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() + ((3 - now.getDay()) % 7),
    9,
    0,
    0
  );
};

export const get2ndOrderWed = (blockedDates?: BlockedDate[] | undefined) => {
  const nextWed = getNextOrderWed(blockedDates);
  return new Date(nextWed.getFullYear(), nextWed.getMonth(), nextWed.getDate() + 7, 9, 0, 0);
};

export const get2ndWed = (blockedDates?: BlockedDate[] | undefined) => {
  const nextWed = getNextWed(blockedDates);
  return new Date(nextWed.getFullYear(), nextWed.getMonth(), nextWed.getDate() + 7, 9, 0, 0);
};

export const getLastWed = (blockedDates?: BlockedDate[] | undefined) => {
  const nextWed = getNextWed(blockedDates);
  return new Date(nextWed.getFullYear(), nextWed.getMonth(), nextWed.getDate() - 7, 9, 0, 0);
};

export const getNextWednesdayString = (blockedDates: BlockedDate[], admin = false) => {
  const nextWednesday = admin ? getNextWed(blockedDates) : getNextOrderWed(blockedDates);
  return nextWednesday.toLocaleDateString("en-GB", {
    weekday: "short",
    month: "short",
    day: "numeric"
  });
};

export const get2ndWednesdayString = (blockedDates: BlockedDate[], admin = false) => {
  const nextWednesday = admin ? get2ndWed(blockedDates) : get2ndOrderWed(blockedDates);
  return nextWednesday.toLocaleDateString("en-GB", {
    weekday: "short",
    month: "short",
    day: "numeric"
  });
};

export const getLastWednesdayString = (blockedDates: BlockedDate[]) => {
  const lastWednesday = getLastWed(blockedDates);
  return lastWednesday.toLocaleDateString("en-GB", {
    weekday: "short",
    month: "short",
    day: "numeric"
  });
};

export function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

export const sumOrdersWithQuantity = (orders: Order[]) => {
  return orders.reduce((acc, order) => acc + Number(order.quantity), 0);
};

export const getPageSlug = (type: string) => {
  switch (type) {
    case "home-page":
      return "/";
    case "about-page":
      return "/about";
    case "contact-page":
      return "/contact";
    case "faq-page":
      return "/faqs";
    case "recipes-page":
      return "/recipes";
    case "news-page":
      return "/blog";
    case "gallery-page":
      return "/gallery";
    default:
      return "/";
  }
};
